import { Box, Link, SxProps, Typography } from "@mui/material";
import { createSvgIcon } from "@mui/material/utils";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link as RouteLink, useLocation } from "react-router-dom";

const ChartBoxOutlineIcon = createSvgIcon(
  <path
    fill="currentColor"
    d="M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17M19 19H5V5H19V19.1M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
  />,
  "ChartBoxOutline"
);

type NavigationCellProps = {
  Icon: any;
  text: any;
  selected?: boolean;
  href: string;
};

function NavigationCell({
  Icon,
  text,
  href,
  selected = false,
}: NavigationCellProps) {
  return (
    <Link component={RouteLink} to={href} underline="none">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 10px 10px 10px",
          color: selected ? "primary.dark" : "primary.light",
        }}
      >
        <Icon sx={{ width: "20px", height: "20px", marginBottom: "2px" }} />
        <Typography sx={{ fontSize: "15px" }}>{text}</Typography>
      </Box>
    </Link>
  );
}

export default function BottomNavigation({ sx }: { sx?: SxProps }) {
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-around",
        ...(sx || {}),
      }}
    >
      <NavigationCell
        href="/"
        Icon={ChartBoxOutlineIcon}
        text="Overview"
        selected={
          ["/", "/add-plan"].indexOf(pathname) !== -1 ||
          pathname.startsWith("/plan")
        }
      />
      <NavigationCell
        href="/contracts"
        Icon={DescriptionOutlinedIcon}
        text="Contracts"
        selected={pathname === "/contracts"}
      />
      <NavigationCell
        href="/settings"
        Icon={SettingsOutlinedIcon}
        text="Settings"
        selected={pathname.indexOf("/settings") !== -1}
      />
    </Box>
  );
}
